import TimmerComponent from "./TimmerComponent";
import React, { useEffect, useMemo, useRef, useState } from "react";
import TypeIt from "typeit-react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import useCustomGameManage from "../../hooks/useCustomGameManage";
import { setGameControl } from "../../redux/slices/gameControlSlice";
import PointComponent from "./PointComponent";
import { getIsTypeItFrozen, setIsTypeItFrozen } from "../../global/globalState";
import useCustomNative from "../../hooks/useCustomNative";
import { useTyping } from "./TypingContext";

const TextQuizComponent = React.forwardRef(({ data }, ref) => {
  const [typeItkey, setTypeItKey] = useState("");
  const [typeItString, setTypeItString] = useState("");
  const [isProgressbar, setIsProgressbar] = useState(false);
  const [kind, seKind] = useState("1"); // 선택지 종류(1:문자열, 2:이미지, 3:기타/영상)
  const [showMessage, setShowMessage] = useState(true);

  const timerDoorRef = useRef(null); // 도어 관리하는 ref
  const timerReadyRef = useRef(null); // Ready 표시 ref

  const typeitInstance = useRef(null); // TypeIt 를 외부 컴포넌트에서 제어 ref
  const getInstance = (instance) => {
    // 아래 <TypeIt /> 의 인스턴스다.
    typeitInstance.current = instance;
    if (ref) ref.current = instance;
  };

  const isTypingRef = useTyping(); // Context에서 isTypingRef 가져오기

  const dispatch = useDispatch();

  /**
   * shallowEqual : 겉에 있는 값들만 비교(1차 레벨, 2차 레벨은 비교 하지 않는다. 얉은 비교)
   *                하지만 객체가 아닌 일반 원시데이터에 사용하는 것은 의미가 없다.
   */
  const gameCount = useSelector(
    (state) => state.gameControlSlice.gameCount,
    shallowEqual
  );

  const optionCount = useSelector(
    (state) => state.gameControlSlice.optionCount
  );

  const gameSpeed = useSelector((state) => state.gameSetSlice.gameSpeed);
  //console.log("^^gameSpeed : ", gameSpeed);

  const { nativeGame } = useCustomNative();

  const { handleGameCallback } = useCustomGameManage(ref);

  // data 객체에서 gameContents 프로퍼티 추출하여 gameContents 변수에 할당(이것이 구조 분해 할당)
  const { gameContents } = data;

  // console.log(
  //   "-----TextQuizComponent-data :",
  //   gameCount,
  //   "#",
  //   optionCount,
  //   gameContents
  // );

  // 개별 선택지 가져오기
  const currentQuestion = useMemo(() => {
    const question = gameContents[gameCount].gameOptions[optionCount].contents; // 선택지 내용

    // console.log(
    //   "^^currentQuestion : ",
    //   gameCount,
    //   "#",
    //   optionCount,
    //   "#",
    //   question
    // );

    return question;
  }, [gameCount, optionCount]);

  // TypeIt 키,문자열 만들기
  useEffect(() => {
    // console.log(
    //   "^^setTypeItString : ",
    //   gameContents[gameCount].gameOptions[optionCount].contents
    // );

    // 선택지 종류(문자열,이미지)
    seKind(gameContents[gameCount].gameOptions[optionCount].kind);

    // 선택지 내용(문자열
    setTypeItString(gameContents[gameCount].gameOptions[optionCount].contents);

    // 키
    setTypeItKey(
      gameContents[gameCount].no +
        "-" +
        gameContents[gameCount].gameOptions[optionCount].no
    );

    return () => {
      if (timerDoorRef.current) {
        clearTimeout(timerDoorRef.current);
      }

      if (timerReadyRef.current) {
        clearTimeout(timerReadyRef.current);
      }
    };
  }, [gameCount, optionCount, gameContents]);

  /**
   * TypeIt 전처리 (타이핑 시작전 처리 작업)
   * 첫번째 선택지에서 초기화 진행(도어,게임정보,StartAt)
   */
  const initOption = () => {
    //console.log("^^initOption : ", gameCount, "/", optionCount);
    if (optionCount === 0) {
      setShowMessage(true); // 첫번째 문제는 Ready 표시함

      const currentGameInfo = (() => {
        const { gameOptions, ...rest } = gameContents[gameCount]; // gameOptions를 제외한 나머지 속성만 반환
        return rest; // gameOptions를 제외한 나머지 속성만 반환
      })(); // 첫번째 선택지에서 딱 한번 저장(현재 게임)

      nativeGame({ type: "sound_door", value1: "Y", value2: "" }); // Door Sound

      dispatch(
        setGameControl({
          isOpenDoor: true, // 문 열기
          currentGameInfo: currentGameInfo,
          gcAtStart: Date.now(), // 매 게임 시작 시간
          prevOptionCount: -1, // 이전 선택지 번호 (답안 선택시 하트 차감하지 않는다.)
        })
      ); // 도어 오픈, 현재 게임 저장

      // 타이머 시작
      if (timerDoorRef.current) {
        clearTimeout(timerDoorRef.current);
      }
      if (timerReadyRef.current) {
        clearTimeout(timerReadyRef.current);
      }

      // 2초후에 미리 문을 닫아둔다.
      timerDoorRef.current = setTimeout(() => {
        dispatch(setGameControl({ isOpenDoor: false }));
        timerDoorRef.current = null; // 타이머 초기화
      }, 2000);

      timerReadyRef.current = setTimeout(() => {
        setShowMessage(false);
        timerReadyRef.current = null; // 타이머 초기화
      }, 5700);

      // 컴포넌트가 언마운트될 때 타이머를 클리어
      return () => {
        if (timerDoorRef.current) {
          clearTimeout(timerDoorRef.current);
        }

        if (timerReadyRef.current) {
          clearTimeout(timerReadyRef.current);
        }
      };
    } else {
      dispatch(setGameControl({ isOpenDoor: false })); // 도어 닫기
    }
  };

  /**
   * TypeIt 후 처리 (타이핑 종료 5초 후 처리 작업)
   */
  const finalOption = () => {
    // console.log(
    //   "^^optIdx:",
    //   gameContents[gameCount].gameOptions[optionCount].gameOptionIdx
    // );
    handleGameCallback({
      value: "",
      ca: "",
      gameType: gameContents[gameCount].gameType,
      gcIdx: gameContents[gameCount].gameContentIdx,
      optIdx: gameContents[gameCount].gameOptions[optionCount].gameOptionIdx,
      optCount: gameContents[gameCount].gameOptions.length,
      totalCount: gameContents.length,
    });
  };

  return (
    <>
      <div className="quiz-box txt-quiz">
        {gameContents && (
          <div className="quiz">
            {isProgressbar && <div className="quiz-progress-fill"></div>}

            {gameContents[gameCount].gameType === "BONUS" && (
              <div className="game-mark">
                <div className="bonus"></div>
              </div>
            )}

            <TimmerComponent data={isProgressbar} />

            <div className="question">
              <div className="txt">
                <p>
                  <TypeIt
                    key={typeItkey}
                    getBeforeInit={(instance) => {
                      //console.log("^^===1. TypeIt-전처리 : ", currentQuestion);

                      getInstance(instance); // TypeIt 인스턴스 저장

                      setIsProgressbar(false);
                      initOption(); // 도어 설정
                      setIsTypeItFrozen(false); // freeze 상태 해제

                      // 첫번째 선택지의 경우 새로운 문제이다.
                      // 6초 대기 이유 : 새 문제의 경우 정답 후보 6개를 차례대로 효과를 주기 위함이다.
                      const _second = optionCount === 0 ? 6000 : 1000;

                      isTypingRef.current = true; // 타이핑 시작

                      // console.log(
                      //   "^^===getBeforeInit-isTypingRef : ",
                      //   isTypingRef.current
                      // );

                      // nativeGame({
                      //   type: "sound_typing",
                      //   value1: "Y",
                      //   value2: _second,
                      // }); // 사운드 one(RN), 재생 N초 후 실행

                      instance
                        .pause(_second) // initGame() 호출 후 1초 대기후 타이핑 시작 (첫번째 선택지는 6초후에 진행)
                        .type(typeItString)
                        .pause(5000); // 타이핑 종료 이후 5초 대기 (게이지 효과, 채점, UPDATE)

                      // instance 반환 (필수)
                      return instance;
                    }}
                    options={{
                      speed: gameSpeed,
                      afterString: (instance) => {
                        isTypingRef.current = false; // 타이핑 종료

                        // console.log(
                        //   "^^===afterString-isTypingRef : ",
                        //   isTypingRef.current
                        // );

                        setIsProgressbar(true);

                        // nativeGame({
                        //   type: "sound_typing",
                        //   value1: "N",
                        //   value2: "",
                        // }); // 사운드 off(RN)
                      },
                      afterComplete: (instance) => {
                        // console.log(
                        //   "^^===After Complete : ",
                        //   getIsTypeItFrozen()
                        // );

                        if (!getIsTypeItFrozen()) {
                          // freeze 상태가 아닐 때만 finalOption 호출
                          finalOption();
                        }
                      },
                    }}
                  />
                </p>
              </div>
              {kind === "2" && (
                <div class="img-box">
                  <div class="frame">
                    {/* <img
                      src={
                        gameContents[gameCount].gameOptions[optionCount].source
                      }
                      alt={`img-${gameCount}-${optionCount}`}
                    /> */}

                    <picture>
                      <source
                        srcSet={
                          gameContents[gameCount].gameOptions[optionCount]
                            .source
                        }
                        type="image/webp"
                      />
                      <img src="/img/noimg.png" alt="noimg"></img>
                    </picture>
                  </div>
                </div>
              )}
              {showMessage && <div className="go-message">Ready...</div>}{" "}
            </div>

            <PointComponent />
          </div>
        )}
      </div>
    </>
  );
});

export default TextQuizComponent;
